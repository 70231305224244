@charset "UTF-8";
.footer {
  background: #3a3a44;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 1500px) {
    .footer {
      padding: 5%; } }
  @media (max-width: 1350px) {
    .footer {
      padding: 5% 2%; } }
  @media (max-width: 1000px) {
    .footer {
      flex-direction: column;
      align-items: center;
      text-align: center; } }
  .footer__column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 34px; }
    .footer__column h5 {
      color: #ffffff;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 15px; }
    .footer__column a,
    .footer__column div {
      color: #ffffff;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 6px; }
  .footer__column-last {
    margin-bottom: 10px; }
  .footer__social__media-title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-family: inherit;
    color: #fff; }
  .footer__social__media-name {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    padding-left: 8px; }
    @media (min-width: 1024px) {
      .footer__social__media-name {
        justify-content: flex-start; } }
  .footer__social__media-img {
    width: 25px;
    height: auto;
    filter: brightness(0) saturate(100%) invert(62%) sepia(71%) saturate(390%) hue-rotate(2deg) brightness(90%) contrast(89%); }
  @media (min-width: 1000px) {
    .footer__logo {
      margin-right: 20px; } }
  .footer_services {
    margin-bottom: 6px; }
    .footer_services > a::after {
      content: "⮟";
      margin-left: 5px; }
    .footer_services__hidden {
      display: none;
      flex-direction: column;
      padding-left: 15px; }
    .footer_services:hover .footer_services__hidden {
      display: flex; }
