.services {
  padding-bottom: 40px; }
  .services_list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px; }
    @media (max-width: 1000px) {
      .services_list {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  .services__item {
    max-height: 330px;
    line-height: 21px;
    text-transform: uppercase;
    color: #cc9d3c;
    text-align: center;
    margin: 1%;
    transition: transform 0.3s ease; }
    .services__item:is(:hover, :focus) {
      transform: scale(1.1); }
    .services__item img {
      width: 100%;
      height: 80%;
      object-fit: cover;
      margin-bottom: 16px;
      filter: brightness(0.5); }
    .services__item > div {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4; }
    .services__item img {
      margin-bottom: 16px; }
    .services__item > div {
      font-size: 24px;
      line-height: 26px; }
    @media (max-width: 1200px) {
      .services__item > div {
        font-size: 20px;
        line-height: 24px; } }
    @media (max-width: 1000px) {
      .services__item img {
        margin-bottom: 4px; } }
    @media (max-width: 700px) {
      .services__item {
        margin-bottom: 10px; }
        .services__item > div {
          font-size: 18px;
          line-height: 22px; } }
    @media (max-width: 600px) {
      .services__item > div {
        font-size: 16px;
        line-height: 20px; } }
    @media (max-width: 500px) {
      .services__item > div {
        font-size: 14px;
        line-height: 18px; } }
    @media (max-width: 500px) {
      .services__item > div {
        font-size: 12px;
        line-height: 14px; } }

.justify {
  justify-content: center; }
