.btn_red {
  background: #ce0f18;
  width: 210px;
  color: white;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  text-align: center; }
  .btn_red:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.btn-white {
  display: block;
  width: fit-content;
  border: 2px solid #ffffff;
  border-radius: 24px;
  background: none;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 14px 50px; }

.btn-white-hover:is(:hover, :focus) {
  color: #cc9d3c;
  border-color: #cc9d3c; }

.btn-transparent {
  display: block;
  border: 1px solid #cc9d3c;
  border-radius: 24px;
  text-transform: uppercase;
  color: #cc9d3c;
  padding: 14px;
  text-align: center;
  width: fit-content;
  transition: background-color 0.3s ease; }
  .btn-transparent:is(:hover) {
    background-color: #cc9d3c;
    color: white; }

.btn-orange {
  display: block;
  background: #cc9d3c;
  border-radius: 24px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 14px 45px;
  text-align: center;
  width: fit-content; }

.btn-bb {
  color: #cc9d3c;
  border-bottom: 1px solid #cc9d3c; }

.btn-catalog {
  display: block;
  border: 1px solid #cc9d3c;
  border-radius: 24px;
  text-transform: uppercase;
  color: #cc9d3c;
  padding: 14px;
  text-align: center;
  margin-top: 18px;
  transition: background-color 0.3s ease; }
  .btn-catalog:is(:hover) {
    background-color: #cc9d3c;
    color: white; }
