.modal__block {
  display: none;
  transition: all 0.2s;
  padding: 21px 18px;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  max-width: 500px;
  background: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .modal__block.active {
    display: block;
    z-index: 9999; }
  .modal__block .validation_error {
    bottom: -20px;
    left: 10px; }

.modal_basket {
  width: 263px;
  top: 13%;
  left: 75%;
  transform: translate(0%, 0%); }
  @media (max-width: 1000px) {
    .modal_basket {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; } }
  .modal_basket .btn {
    margin-top: 32px; }
    .modal_basket .btn button,
    .modal_basket .btn a {
      width: 100%;
      font-size: 14px;
      line-height: 14px;
      padding: 14px;
      margin-bottom: 10px; }
  .modal_basket__products {
    max-height: 240px;
    overflow-y: auto; }
    .modal_basket__products::-webkit-scrollbar {
      width: 3px; }
    .modal_basket__products::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 8px; }

.modal_quick-call {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 355px;
  padding: 50px;
  background-color: #fff; }
  @media screen and (min-width: 425px) {
    .modal_quick-call {
      width: 400px; } }
  @media screen and (min-width: 768px) {
    .modal_quick-call {
      width: 500px; } }
  .modal_quick-call input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #a8a8a8; }
  .modal_quick-call .validation_container {
    margin-bottom: 30px; }
  .modal_quick-call button {
    margin: 0 auto; }

.modal_close {
  transition: all 0.2s;
  color: #000000;
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 25px; }
  .modal_close:hover {
    color: #cc9d3c; }

.modal_svg {
  margin-right: 8px; }

.modal_title {
  font-size: 21px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3a3a44;
  text-align: center;
  margin-bottom: 10px; }
  .modal_title-2 {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px; }

.modal_form {
  margin-top: 40px; }

.modal_forget_btn {
  display: block;
  width: 100%;
  margin: 30px 0;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline; }

.modal_btn__block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }
  .modal_btn__block .register {
    border: 1px solid; }

.modal__btn-center {
  display: flex;
  justify-content: center; }

.modal_new_user {
  text-transform: uppercase; }

.modal .mailing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  .modal .mailing_choice {
    display: flex; }
    .modal .mailing_choice__item {
      margin-right: 20px; }

.modal_auth-social {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .modal_auth-social__title {
    color: #828282;
    font-size: 14px; }
  .modal_auth-social a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black; }
    .modal_auth-social a img {
      margin-right: 5px; }

@media (max-width: 480px) {
  .modal_btn__block {
    flex-direction: column; }
    .modal_btn__block .modal_btn {
      width: 100%;
      margin-bottom: 10px; }
    .modal_btn__block .modal_open {
      width: 100%; }
  .modal_title {
    justify-content: flex-start;
    padding-right: 30px; }
  .modal__block {
    padding: 15px; }
  .modal_form {
    padding-top: 0px;
    margin-top: 10px; } }

.input_section {
  margin-bottom: 30px; }
  .input_section__title {
    color: #333333;
    font-size: 14px;
    margin: 0 0 5px 25px; }
  .input_section input {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-sizing: border-box;
    height: 48px;
    padding: 15px 24px; }
  .input_section textarea {
    width: 100%;
    border: 1px solid #f2f2f2;
    padding: 15px 24px;
    box-sizing: border-box; }

.modal_quick-call-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transform: none; }
