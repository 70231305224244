.header {
  width: 100vw; }
  .header .header__bottom {
    padding: 2% 4%; }
    @media screen and (min-width: 768px) {
      .header .header__bottom {
        padding: 2% 6%; } }
  @media (max-width: 1440px) {
    .header .header__bottom nav a {
      font-size: 14px; } }
  @media (max-width: 1000px) {
    .header {
      position: fixed;
      z-index: 1000; }
      .header .header__bottom nav a {
        font-size: 16px; } }
  .header > div {
    align-items: center; }
  .header__logo img {
    width: 75px;
    height: auto; }
    @media screen and (min-width: 768px) {
      .header__logo img {
        width: 95px; } }
    @media screen and (min-width: 1024px) {
      .header__logo img {
        width: 125px; } }
  .header__top {
    width: 100%;
    height: 46px;
    background: #3a3a44;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff; }
    @media (max-width: 1000px) {
      .header__top {
        font-size: 12px; } }
    .header__top .language__block select {
      background: #3a3a44;
      text-transform: uppercase;
      margin-left: 30px; }
    .header__top .language__block a {
      height: 16px; }
  .header__phone {
    width: max-content; }
    @media (max-width: 1000px) {
      .header__phone div,
      .header__phone span {
        display: none; } }
  .header__right > div:not(.header__search) {
    margin-right: 15px; }
    @media (max-width: 1000px) {
      .header__right > div:not(.header__search) {
        height: 30px; } }
  .header__search {
    display: flex;
    align-items: center;
    position: relative; }
    @media (max-width: 1000px) {
      .header__search {
        display: none; } }
    .header__search button:first-of-type {
      right: 10%; }
    .header__search img {
      position: absolute;
      right: 10%;
      top: 25%; }
  .header__bottom {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); }
    @media (max-width: 1000px) {
      .header__bottom {
        height: 76px; } }
    .header__bottom input[type="search"] {
      background: #f5f5f5;
      border-radius: 24px;
      font-size: 14px;
      line-height: 14px;
      padding: 13px 16px;
      border: none; }
      .header__bottom input[type="search"]::placeholder {
        color: #bababa; }
    .header__bottom a {
      text-transform: uppercase;
      color: #000000; }
    @media (max-width: 1000px) {
      .header__bottom nav {
        opacity: 0;
        position: absolute;
        left: -1000px;
        top: 76px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        transition: all 0.8s;
        width: 100vw;
        height: 100vh;
        z-index: -1; }
        .header__bottom nav > a,
        .header__bottom nav span {
          width: 100%;
          padding: 13px 24px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
        .header__bottom nav button {
          display: none; } }
    .header__bottom nav a {
      margin-right: 26px; }
      .header__bottom nav a.active {
        color: #cc9d3c; }
      .header__bottom nav a:hover {
        color: #cc9d3c; }
    .header__bottom nav .nav_services {
      position: relative; }
      .header__bottom nav .nav_services__hidden {
        display: none;
        white-space: nowrap;
        left: 0;
        z-index: 100;
        flex-direction: column;
        background: #ffffff;
        padding: 10px; }
        @media (min-width: 1000px) {
          .header__bottom nav .nav_services__hidden {
            position: absolute;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); } }
        .header__bottom nav .nav_services__hidden a {
          margin-bottom: 5px;
          text-transform: initial; }
      .header__bottom nav .nav_services:hover {
        padding-bottom: 10px; }
        .header__bottom nav .nav_services:hover .nav_services__hidden {
          display: flex; }
    .header__bottom .btn-orange {
      display: none; }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 55px;
    height: 55px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px; }
    @media (max-width: 1000px) {
      .header_hamburger {
        display: flex; } }
  .header_line {
    transition: all 0.2s;
    position: absolute;
    width: 31px;
    height: 3px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #000000; }
    .header_line_1 {
      top: 16px;
      left: 12px; }
    .header_line_2 {
      top: 24px;
      left: 12px; }
    .header_line_3 {
      top: 32px;
      left: 12px; }
  .header.active .header_line_1 {
    top: 24px;
    left: 12px;
    transform: rotate(45deg); }
  .header.active .header_line_2 {
    left: -100px; }
  .header.active .header_line_3 {
    top: 24px;
    left: 12px;
    transform: rotate(-45deg); }
  .header.active nav {
    opacity: 1;
    left: 0;
    top: 76px;
    z-index: 3; }
  @media screen and (min-width: 1000px) {
    .header_left {
      display: none !important; } }
  .header.search .header__search {
    display: block; }
  .header.search .header_left,
  .header.search .header__phone,
  .header.search .search__btn-mob,
  .header.search [data-href="modal_basket"] {
    display: none; }
  .header.search .header__right,
  .header.search .header__search {
    width: 100%; }
  .header.search .search__input {
    width: calc(100% - 16px); }

.search__btn {
  position: absolute;
  right: 0%;
  top: 25%;
  color: rgba(204, 157, 60, 0.75); }
  @media (min-width: 1000px) {
    .search__btn-mob, .search__btn-close {
      display: none; } }

.basket_product {
  display: flex;
  align-items: flex-start; }
  .basket_product img {
    width: 91px;
    height: 132px;
    object-fit: cover; }
  .basket_product__right {
    margin-left: 18px; }
    .basket_product__right span {
      color: #a9a9a9; }
  .basket_product__title {
    font-size: 16px; }
  .basket_product__code {
    font-size: 14px;
    line-height: 14px;
    color: #a9a9a9; }

.social__block {
  display: flex;
  align-items: center;
  margin-left: 20px; }
  .social__block a {
    margin-right: 10px; }
  .social__block img {
    width: 16px;
    height: 16px;
    filter: brightness(0) saturate(100%) invert(59%) sepia(67%) saturate(403%) hue-rotate(2deg) brightness(95%) contrast(92%); }
