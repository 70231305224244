.swiper-container {
  position: relative; }

.projects-swiper-slide {
  width: 250px !important; }

.swiper-pagination-progressbar {
  width: 25%;
  height: 2px;
  background: #dcdcdc; }
  .swiper-pagination-progressbar-fill {
    background-color: #000000; }

.swiper-pagination-current,
.currentZero {
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 99px;
  text-align: center;
  color: #272c35; }

.swiper-pagination-total,
.totalZero {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #bdbdbd; }

.top_slider-pagination {
  bottom: 8%; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #000000; }

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  width: 42px;
  height: 42px;
  background: rgba(204, 157, 60, 0.75); }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 12px;
    color: #ffffff; }

.swiper-button-disabled {
  background: rgba(217, 217, 217, 0.45); }

.top-slider-btn-prev,
.top-slider-btn-next {
  top: 86%; }
  .top-slider-btn-prev:after,
  .top-slider-btn-next:after {
    color: #ffffff; }

.top-slider-btn-prev {
  left: 55%; }

.top-slider-btn-next {
  right: 0; }

.product__slider {
  overflow: hidden; }

.product-slider-btn-next:after,
.product-slider-btn-prev:after {
  color: black; }

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  text-rendering: auto !important; }
