main {
  position: relative; }

.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */ }

.padding_navbar {
  padding-top: 165px; }

.section_height {
  min-height: calc(100vh - 137px); }

.container {
  position: relative;
  padding: 0 10%;
  width: 100%; }
  @media (max-width: 500px) {
    .container {
      padding: 0% 4%; } }

.container-2 {
  position: relative;
  padding: 5% 10%;
  width: 100%; }
  @media (max-width: 500px) {
    .container-2 {
      padding: 2% 4%; } }

.upper_text {
  text-transform: uppercase; }

.swiper-button::after {
  transform: scale(0.6);
  color: #5d6b7c; }

.swiper-button::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  opacity: 0.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%; }

.swiper-button.swiper-button-disabled::after {
  color: #fff; }

.swiper-pagination-bullet {
  position: relative;
  width: 5px;
  margin: 0px 15px !important;
  height: 5px;
  opacity: 1;
  background: #000 !important; }
  .swiper-pagination-bullet:after {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 5px;
    height: 5px;
    border: 1px solid #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%; }
  .swiper-pagination-bullet-active:after {
    width: 30px;
    height: 30px; }

.absolute_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.center {
  position: absolute;
  left: 50%;
  transform: translate(-50%); }

@media (max-width: 1000px) {
  .first_padding {
    padding-top: 124px; } }

.flex {
  display: flex;
  align-items: flex-start; }

.flex-normal {
  display: flex;
  align-items: center; }

.flex-sb {
  display: flex;
  justify-content: space-between; }

.flex-wrap {
  display: flex;
  flex-wrap: wrap; }

.flex-3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.stages {
  padding: 5% 10%;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 1600px) {
    .stages {
      padding: 5%; } }
  @media (max-width: 1000px) {
    .stages .flex-normal {
      flex-direction: column; }
    .stages .stages__item {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      border-right: none; } }
  .stages h2 {
    text-align: center; }
  .stages__item {
    width: 20%;
    text-align: center;
    color: #ffffff;
    padding: 0 26px 26px 26px; }
    @media (min-width: 1000px) {
      .stages__item:not(.stages__item:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.45); } }
    .stages__item img {
      width: 68px;
      height: 68px; }
  .stages__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 21px 0 12px 0; }
  .stages h2 {
    color: #ffffff; }
