@charset "UTF-8";
.banner {
  position: relative; }
  @media (max-width: 1000px) {
    .banner {
      padding-top: 123px; } }
  .banner__slide {
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: color;
    overflow: hidden; }
  .banner__slide:not(.swiper-slide-active) .banner__img {
    animation: none;
    /* Вимкнути анімацію для неактивних слайдів */ }
  .banner .swiper-slide-prev .banner__img,
  .banner .swiper-slide-next .banner__img {
    animation: none;
    /* Вимкнути анімацію для попереднього та наступного слайдів */ }
  .banner__img {
    width: 100%;
    height: auto;
    animation: zoom-in-zoom-out 5s ease;
    object-fit: cover;
    z-index: -1; }

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.1, 1.1); }
  100% {
    transform: scale(1, 1); } }
  .banner__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    text-transform: uppercase;
    color: #ffffff; }
    @media (max-width: 1000px) {
      .banner__title {
        font-weight: 400;
        font-size: 40px;
        line-height: 48px; } }
  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5); }
  .banner__btn {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 96px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1000px) {
      .banner__btn {
        bottom: 4%; } }
    .banner__btn .banner-button-next,
    .banner__btn .banner-button-prev {
      position: static; }
    .banner__btn .banner-button-prev {
      right: 10px; }
  .banner__text {
    position: absolute;
    max-width: 570px;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: #ffffff;
    font-size: 24px;
    line-height: 28px;
    font-size: 40px;
    line-height: 45px;
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 100;
    user-select: all; }
  .banner__text-2 {
    max-width: 900px; }
  .banner__description {
    margin: 21px 0 42px 0;
    color: #ffffff;
    font-size: 24px;
    line-height: 28px; }
    @media (max-width: 1000px) {
      .banner__description {
        font-size: 20px;
        line-height: 24px; } }
