@media (max-width: 1000px) {
  .home__top .benefits {
    flex-direction: column; }
    .home__top .benefits .benefits__item {
      width: 100%; }
      .home__top .benefits .benefits__item:not(:last-child) {
        margin-bottom: 37px; } }

.home__top .benefits__item {
  width: 30%;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 10px; }

.home__top .benefits__top {
  margin-bottom: 10px; }

.home__top .benefits__title {
  font-size: 24px;
  line-height: 28px;
  color: #3a3a44; }

.home__top .benefits__num {
  font-weight: 700;
  font-size: 56px;
  line-height: 56px;
  text-transform: uppercase;
  color: rgba(15, 11, 2, 0.34);
  margin-left: 30px; }

.projects {
  width: 100%;
  padding: 5% 0;
  overflow: hidden; }
  .projects img {
    width: 100%;
    height: 400px;
    object-fit: cover; }
  .projects h2 {
    padding-left: 10%;
    padding-right: 10%;
    text-align: center; }

.home__banner {
  overflow: hidden;
  position: relative; }
  .home__banner-swiper {
    width: 100%;
    height: 100vh; }
    @media screen and (min-width: 1024px) {
      .home__banner-swiper {
        height: calc(100vh - 165px); } }
    .home__banner-swiper-slide {
      width: 100%;
      height: 100%; }
      .home__banner-swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.5);
        animation: zoom-in-zoom-out 5s ease; }
      .home__banner-swiper-slide-text-block {
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 570px; }
        @media screen and (min-width: 768px) {
          .home__banner-swiper-slide-text-block {
            left: 10%;
            transform: translate(0, -50%); } }
        .home__banner-swiper-slide-text-block p {
          margin: 0;
          margin-bottom: 24px;
          font-size: 36px;
          line-height: 42px;
          font-weight: 500;
          text-align: center;
          width: 100%;
          color: #fff; }
          @media screen and (min-width: 768px) {
            .home__banner-swiper-slide-text-block p {
              font-size: 45px;
              line-height: 52px;
              text-align: start; } }
        .home__banner-swiper-slide-text-block button {
          width: 240px;
          margin: 0 auto;
          font-size: 18px;
          line-height: 20px;
          padding: 16px;
          text-align: center;
          background-color: #cc9d3c;
          border: transparent; }
          @media screen and (min-width: 768px) {
            .home__banner-swiper-slide-text-block button {
              margin: 0; } }
    .home__banner-swiper .swiper-slide-prev .home__banner-img,
    .home__banner-swiper .swiper-slide-next .home__banner-img {
      animation: none; }
  .home__banner-buttons {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    cursor: pointer; }
  .home__banner-button-prev, .home__banner-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #fff;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 200ms ease; }
    .home__banner-button-prev:is(:hover, :focus), .home__banner-button-next:is(:hover, :focus) {
      background-color: #cc9d3c; }
    .home__banner-button-prev img, .home__banner-button-next img {
      min-width: 6px;
      min-height: 14px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(49deg) brightness(104%) contrast(102%); }
  .home__banner-button-prev {
    rotate: 180deg; }
  .home__banner-button-next {
    margin-left: 10px; }

.invisible-container {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s; }

.visible {
  visibility: visible;
  opacity: 1; }

* {
  font-display: swap; }
