.regular_title {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal; }
  .regular_title_0 {
    font-size: 12px; }
  .regular_title_1 {
    font-size: 14px; }
  .regular_title_2 {
    font-size: 16px; }
  .regular_title_3 {
    font-size: 18px; }
  .regular_title_6 {
    font-size: 30px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_1 {
    font-size: 14px; }
  .bold_title_2 {
    font-size: 16px; }

.color_black {
  color: #000000; }

.color_white {
  color: #fff; }

.color_red {
  color: #CF1A20; }

.color_gray {
  color: #828282; }

.color_gray1 {
  color: #333333; }

.color_gray2 {
  color: #272C35; }

h1 {
  color: #3A3A44;
  font-size: 40px;
  line-height: 40px;
  text-transform: capitalize;
  margin-top: 21px;
  margin-bottom: 32px; }

h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  text-transform: uppercase;
  color: #3A3A44;
  padding: 80px 0 42px 0; }
  @media (max-width: 1000px) {
    h2 {
      font-size: 34px;
      line-height: 36px; } }

h3 {
  font-size: 28px;
  line-height: 28px;
  color: #3A3A44;
  text-transform: capitalize;
  font-weight: 400; }

.title_2 {
  padding: 0 0 42px 0; }
