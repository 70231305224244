.contact-us {
  position: relative; }
  @media (max-width: 1000px) {
    .contact-us form {
      position: static;
      margin: 0 24px;
      width: calc(100% - 48px);
      padding: 36px 24px;
      text-align: center; }
    .contact-us button {
      margin: 0 auto; } }
  .contact-us iframe {
    position: relative;
    bottom: -8px;
    width: 100%;
    height: 600px;
    border: 0; }
  .contact-us__form {
    position: absolute;
    z-index: 10;
    top: 5%;
    right: 10%;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    padding: 40px 66px;
    width: 540px; }
    .contact-us__form h3 {
      font-size: 21px;
      line-height: 21px;
      text-transform: uppercase;
      color: #3a3a44;
      margin-bottom: 36px; }
    .contact-us__form .form_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 35px; }
      .contact-us__form .form_container > div {
        width: 48%;
        margin-bottom: 32px; }
        @media (max-width: 500px) {
          .contact-us__form .form_container > div {
            width: 100%; } }
    .contact-us__form input,
    .contact-us__form textarea {
      border: none;
      border-bottom: 1px solid #a8a8a8;
      width: 100%; }

.g-recaptcha-wrapper {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  width: 100% !important;
  margin-top: 20px; }

.contact-us-btn:disabled {
  background-color: #563a00; }
